import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import AdminTweet from "../components/AdminTweet";
import {
  fetchTweetsWithAllComments,
  archiveTweets,
  unarchiveTweets,
} from "../firebaseFunctions/tweets";
// import appConstants from "../utils/appConstants";
import AdminTweetDetails from "../components/AdminTweetDetails";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faSearch,
  faTrashArrowUp,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 1 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AdminTweets() {
  const [value, setValue] = React.useState(0);
  const [reload, setReload] = React.useState(false);
  const [reloadDetails, setReloadDetails] = React.useState(false);
  const [state, setState] = React.useState({
    loadingTweets: true,
    loadingBulkArchive: false,
    loadingBulkUnarchive: false,
    showTweetDetails: false,
    openedTweet: "",
    tweets: [],
    checkedTweets: [],
    query: "",
  });

  const fetchTweets = async () => {
    try {
      const tweets = await fetchTweetsWithAllComments();
      // const tweets = appConstants.TEST_DATA;
      // console.log(tweets);
      setState((prevState) => ({
        ...prevState,
        tweets: tweets,
        loadingTweets: false,
      }));
      // need to return the tweets to update state in other functions
      return tweets;
    } catch (e) {
      console.log("fetch tweets error:", e);
    }
  };

  // useEffect for when child components are updated
  React.useEffect(() => {
    fetchTweets();
  }, [reload]);

  // useEffect for when tweet details are updated -> reset the value of state.openedTweet
  React.useEffect(() => {
    // following check is to avoid opening tweet details when no tweet is selected
    if (state.openedTweet) {
      fetchTweets()
        .then((twts) => {
          const twt = twts?.find(
            (element) => element.id === state.openedTweet.id
          );
          setState((prevState) => ({
            ...prevState,
            openedTweet: twt,
          }));
        })
        .catch((e) => {
          console.log("fetch tweets error:", e);
        });
    }
  }, [reloadDetails]);

  const handleTabChange = (event, newTabValue) => {
    setValue(newTabValue);
  };

  const handleQueryChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      query: e.target.value,
    }));
  };

  const showTweetDetails = (tweet) => {
    setState((prevState) => ({
      ...prevState,
      openedTweet: tweet,
      showTweetDetails: true,
    }));
  };

  const hideTweetDetails = () => {
    setState((prevState) => ({
      ...prevState,
      openedTweet: "",
      showTweetDetails: false,
    }));
  };

  const onArchiveTweet = async () => {
    try {
      // console.log("archiving tweet " + state.openedTweet.id);
      await archiveTweets([state.openedTweet.id]);
      setReload(!reload);
      hideTweetDetails();
    } catch (e) {
      console.log("archive tweet error:", e);
    }
  };

  const onUnarchiveTweet = async () => {
    try {
      console.log("unarchiving tweet " + state.openedTweet.id);
      await unarchiveTweets([state.openedTweet.id]);
      setReload(!reload);
      hideTweetDetails();
    } catch (e) {
      console.log("unarchive tweet error:", e);
    }
  };

  const onBulkArchive = async () => {
    if (!state.checkedTweets?.length > 0) return;
    if (state.checkedTweets?.length > 500) {
      alert("please select less than 500 items");
      return;
    }
    setState((prevState) => ({
      ...prevState,
      loadingBulkArchive: true,
    }));
    await archiveTweets(state.checkedTweets);
    setState((prevState) => ({
      ...prevState,
      loadingBulkArchive: false,
      checkedTweets: [],
    }));
    setReload(!reload);
  };

  const onBulkUnarchive = async () => {
    if (!state.checkedTweets?.length > 0) return;
    if (state.checkedTweets?.length > 500) {
      alert("please select less than 500 items");
      return;
    }
    setState((prevState) => ({
      ...prevState,
      loadingBulkUnarchive: true,
    }));
    await unarchiveTweets(state.checkedTweets);
    setState((prevState) => ({
      ...prevState,
      loadingBulkUnarchive: false,
      checkedTweets: [],
    }));
    setReload(!reload);
  };

  const selectOneItem = (e) => {
    let tweetsList = state.checkedTweets;
    if (e.target.checked) {
      tweetsList.push(e.target.id);
    } else {
      tweetsList = tweetsList.filter((i) => i !== e.target.id);
    }
    setState((prevState) => ({
      ...prevState,
      checkedTweets: tweetsList,
    }));
  };

  const selectAllArchivedItems = (e) => {
    let tweetsList = [];
    let checkBoxes = document
      .querySelector("#archived-tweets-container")
      .querySelectorAll("input[type=checkbox]");
    if (e.target.checked) {
      checkBoxes.forEach((item) => {
        tweetsList.push(item.id);
        item.checked = true;
      });
      setState((prevState) => ({
        ...prevState,
        checkedTweets: tweetsList,
      }));
    } else {
      checkBoxes.forEach((item) => {
        item.checked = false;
      });
      setState((prevState) => ({
        ...prevState,
        checkedTweets: [],
      }));
    }
  };

  const selectAllUnarchivedItems = (e) => {
    let tweetsList = [];
    let checkBoxes = document
      .querySelector("#unarchived-tweets-container")
      .querySelectorAll("input[type=checkbox]");
    if (e.target.checked) {
      checkBoxes.forEach((item) => {
        tweetsList.push(item.id);
        item.checked = true;
      });
      setState((prevState) => ({
        ...prevState,
        checkedTweets: tweetsList,
      }));
    } else {
      checkBoxes.forEach((item) => {
        item.checked = false;
      });
      setState((prevState) => ({
        ...prevState,
        checkedTweets: [],
      }));
    }
  };

  return (
    <>
      {state.showTweetDetails ? (
        <>
          <Box sx={{ paddingY: 1 }}>
            <IconButton sx={{ padding: 1 }} onClick={hideTweetDetails}>
              <FontAwesomeIcon icon={faArrowLeft} className="icon-size-large" />
            </IconButton>
            {state.openedTweet?.archived ? (
              <IconButton
                sx={{ padding: 1 }}
                onClick={onUnarchiveTweet}
                size="small"
              >
                <FontAwesomeIcon icon={faTrashArrowUp} />
              </IconButton>
            ) : (
              <IconButton
                sx={{ padding: 1 }}
                onClick={onArchiveTweet}
                size="small"
              >
                <FontAwesomeIcon icon={faTrash} />
              </IconButton>
            )}
          </Box>
          <AdminTweetDetails
            tweet={state.openedTweet}
            reload={reloadDetails}
            setReload={setReloadDetails}
          />
        </>
      ) : (
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              backgroundColor: "white",
              borderRadius: "25px",
              padding: 2,
              mb: 2,
              mx: "auto",
              display: "flex",
              justifyContent: "between",
              alignItems: "center",
            }}
          >
            <label className="custom-search-bar" htmlFor="search-query">
              <FontAwesomeIcon
                icon={faSearch}
                className="custom-search-icon icon-size-large"
              />
              <input
                type="text"
                id="search-query"
                placeholder="Search Tweets..."
                value={state.query}
                onChange={handleQueryChange}
              ></input>
            </label>
          </Box>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleTabChange}
              aria-label="basic tabs"
            >
              <Tab label="Active" {...a11yProps(0)} />
              <Tab label="Archived" {...a11yProps(1)} />
            </Tabs>
          </Box>
          {state.loadingTweets ? (
            <Container
              sx={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "36vh",
              }}
            >
              <CircularProgress />
            </Container>
          ) : (
            <>
              <TabPanel value={value} index={0}>
                {state.tweets?.filter((item) => !item?.archived).length > 0 && (
                  <Box
                    sx={{
                      paddingBottom: 2,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <input
                      onChange={selectAllArchivedItems}
                      style={{
                        margin: "0 1rem 0 0",
                      }}
                      type="checkbox"
                    />
                    {state.loadingBulkArchive ? (
                      <CircularProgress
                        size={14}
                        sx={{
                          alignSelf: "center",
                          paddingY: 1,
                          paddingX: 0.9,
                        }}
                      />
                    ) : (
                      <IconButton
                        size="small"
                        sx={{ padding: 1, alignSelf: "center" }}
                        onClick={onBulkArchive}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </IconButton>
                    )}
                    {state.checkedTweets.length > 0 && (
                      <Typography ml={2}>
                        {state.checkedTweets.length} selected
                      </Typography>
                    )}
                  </Box>
                )}
                <Box id="archived-tweets-container">
                  {state.tweets?.filter((item) => !item?.archived).length >
                  0 ? (
                    state.tweets
                      ?.filter((item) => !item?.archived)
                      ?.filter((item) => {
                        if (state.query === "") return item;
                        else if (
                          item.body
                            .toLowerCase()
                            .includes(state.query.toLowerCase())
                        ) {
                          return item;
                        }
                      })
                      ?.map((item, id) => {
                        return (
                          <div key={id}>
                            <input
                              id={item.id}
                              onChange={selectOneItem}
                              style={{
                                float: "left",
                                margin: "1rem 0.5rem 0 0",
                              }}
                              type="checkbox"
                            />
                            <div
                              className="pointer"
                              onClick={() => {
                                showTweetDetails(item);
                              }}
                            >
                              <AdminTweet
                                reload={reload}
                                setReload={setReload}
                                tweet={item}
                              />
                            </div>
                          </div>
                        );
                      })
                  ) : (
                    <Container
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <Typography mt={2}>
                        Active tweets will show here
                      </Typography>
                    </Container>
                  )}
                </Box>
              </TabPanel>
              <TabPanel value={value} index={1}>
                {state.tweets?.filter((item) => item?.archived).length > 0 && (
                  <Box
                    sx={{
                      paddingBottom: 2,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <input
                      onChange={selectAllUnarchivedItems}
                      style={{
                        margin: "0 1rem 0 0",
                      }}
                      type="checkbox"
                    />
                    {state.loadingBulkUnarchive ? (
                      <CircularProgress
                        size={14}
                        sx={{
                          alignSelf: "center",
                          paddingY: 1,
                          paddingX: 0.9,
                        }}
                      />
                    ) : (
                      <IconButton
                        size="small"
                        sx={{ padding: 1, alignSelf: "center" }}
                        onClick={onBulkUnarchive}
                      >
                        <FontAwesomeIcon icon={faTrashArrowUp} />
                      </IconButton>
                    )}
                    {state.checkedTweets.length > 0 && (
                      <Typography ml={2}>
                        {state.checkedTweets.length} selected
                      </Typography>
                    )}
                  </Box>
                )}
                <Box id="unarchived-tweets-container">
                  {state.tweets?.filter((item) => item?.archived).length > 0 ? (
                    state.tweets
                      ?.filter((item) => item?.archived)
                      ?.filter((item) => {
                        if (state.query === "") return item;
                        else if (
                          item.body
                            .toLowerCase()
                            .includes(state.query.toLowerCase())
                        ) {
                          return item;
                        }
                      })
                      ?.map((item, id) => {
                        return (
                          <div key={id}>
                            <input
                              id={item.id}
                              onChange={selectOneItem}
                              style={{
                                float: "left",
                                margin: "1rem 0.5rem 0 0",
                              }}
                              type="checkbox"
                            />
                            <div
                              className="pointer"
                              onClick={() => {
                                showTweetDetails(item);
                              }}
                            >
                              <AdminTweet
                                reload={reload}
                                setReload={setReload}
                                tweet={item}
                              />
                            </div>
                          </div>
                        );
                      })
                  ) : (
                    <Container
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <Typography mt={2}>
                        Archived tweets will show here
                      </Typography>
                    </Container>
                  )}
                </Box>
              </TabPanel>
            </>
          )}
        </Box>
      )}
    </>
  );
}
