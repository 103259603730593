import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import appConstants from "../utils/appConstants";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { faComment } from "@fortawesome/free-regular-svg-icons";

export default function AdminTweet(props) {
  return (
    <Card
      sx={{
        boxShadow: 0,
        borderRadius: 4,
        marginBottom: 2,
        maxWidth: { lg: "75vw", md: "70vw", sm: "90vw", xs: "85vw" },
        marginX: "auto",
      }}
    >
      <Grid container>
        <Grid
          item
          sx={{ alignSelf: "center", padding: 0 }}
          xs={6}
          sm={3}
          md={3}
          lg={2}
        >
          <CardHeader
            sx={{ padding: 1 }}
            titleTypographyProps={{
              fontWeight: "bold",
              fontSize: "0.7rem",
            }}
            subheaderTypographyProps={{
              textTransform: "uppercase",
              fontSize: "0.6rem",
            }}
            avatar={
              <Avatar aria-label="userIcon">
                {/* <img src={props?.tweet?.userIcon} alt="user" /> */}
              </Avatar>
            }
            title={props?.tweet?.userName}
            subheader={new Date(
              props?.tweet?.tweetCreatedAt
            ).toLocaleDateString(undefined, appConstants.DATE_FORMAT)}
          />
        </Grid>
        <Grid
          item
          sx={{
            alignSelf: "center",
            display: { xs: "flex", sm: "none" },
            justifyContent: "right",
            pr: 2,
          }}
          xs={6}
          sm={3}
          md={3}
          lg={2}
        >
          <CardActions>
            <IconButton sx={{ padding: 0 }} aria-label="upvote" size="small">
              <FontAwesomeIcon icon={faArrowUp} className="icon-size-medium" />
              <Typography ml={1} variant="body2">
                {props?.tweet?.upvotes ? props?.tweet?.upvotes?.length : 0}
              </Typography>
            </IconButton>
            <IconButton sx={{ padding: 0 }} aria-label="downvote" size="small">
              <FontAwesomeIcon
                icon={faArrowDown}
                className="icon-size-medium"
              />
              <Typography ml={1} variant="body2">
                {props?.tweet?.downvotes ? props?.tweet?.downvotes?.length : 0}
              </Typography>
            </IconButton>
            <IconButton sx={{ padding: 0 }} aria-label="comment" size="small">
              <FontAwesomeIcon icon={faComment} className="icon-size-medium" />
              <Typography ml={1} variant="body2">
                {props?.tweet?.comments ? props?.tweet?.comments?.length : 0}
              </Typography>
            </IconButton>
          </CardActions>
        </Grid>
        <Grid item sx={{ alignSelf: "center" }} xs={12} sm={7} md={7} lg={8}>
          <CardContent>
            <Typography
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              variant="body2"
              component="p"
              color="text.primary"
            >
              {props?.tweet?.body}
            </Typography>
          </CardContent>
        </Grid>
        <Grid
          item
          sx={{
            alignSelf: "center",
            display: { xs: "none", sm: "flex" },
            justifyContent: "right",
          }}
          xs={12}
          sm={2}
          md={2}
          lg={2}
        >
          <CardActions sx={{ padding: 2 }}>
            <IconButton sx={{ padding: 0 }} aria-label="upvote">
              <FontAwesomeIcon icon={faArrowUp} className="icon-size-medium" />
              <Typography ml={1} variant="body2">
                {props?.tweet?.upvotes ? props?.tweet?.upvotes?.length : 0}
              </Typography>
            </IconButton>
            <IconButton sx={{ padding: 0 }} aria-label="downvote">
              <FontAwesomeIcon
                icon={faArrowDown}
                className="icon-size-medium"
              />
              <Typography ml={1} variant="body2">
                {props?.tweet?.downvotes ? props?.tweet?.downvotes?.length : 0}
              </Typography>
            </IconButton>
            <IconButton sx={{ padding: 0 }} aria-label="comment">
              <FontAwesomeIcon icon={faComment} className="icon-size-medium" />
              <Typography ml={1} variant="body2">
                {props?.tweet?.comments ? props?.tweet?.comments?.length : 0}
              </Typography>
            </IconButton>
          </CardActions>
        </Grid>
      </Grid>
    </Card>
  );
}
