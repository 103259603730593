import React from "react";
import { Typography } from "@mui/material";
import appConstants from "../utils/appConstants";

const ToxicityLabel = (props) => {
  return (
    <Typography
      component="span"
      sx={{
        borderRadius: 5,
        mb: 1,
        mr: 1,
        px: 1.1,
        py: 0.3,
        color: appConstants.PREDICTIONS[props.type].labelTextColor,
        backgroundColor:
          appConstants.PREDICTIONS[props.type].labelBackgoundColor,
      }}
    >
      {appConstants.PREDICTIONS[props.type].label}
    </Typography>
  );
};

export default ToxicityLabel;
