import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import appConstants from "../utils/appConstants";
import CircularProgress from "@mui/material/CircularProgress";
import { postComment } from "../firebaseFunctions/tweets";
import { styled } from "@mui/material/styles";
import { upvoteTweet, downvoteTweet } from "../firebaseFunctions/tweets";
import { getTimeAgo } from "../utils/helpers";
import EditCommentModal from "../components/EditCommentModal";
import ToxicityLabel from "../components/ToxicityLabel";
import TwitterUserDetails from "./TwitterUserDetails";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUp,
  faArrowDown,
  faEllipsisV,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { faPaperPlane, faComment } from "@fortawesome/free-regular-svg-icons";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginTop: !expand ? "5px" : "-5px",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.short,
  }),
}));

export default function Tweet(props) {
  const [expanded, setExpanded] = React.useState(false);
  const [comment, setComment] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const [userExists, setUserExists] = React.useState(false);
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  React.useEffect(() => {
    // check whether or not the user is logged in
    if (
      JSON.parse(localStorage.getItem("user")) &&
      "userId" in JSON.parse(localStorage.getItem("user"))
    ) {
      setUserExists(true);
    } else {
      setUserExists(false);
    }
  }, [props.reload]);

  const seeAllComments = () => {
    setExpanded(!expanded);
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleCommentPost = async (tweetId) => {
    try {
      setLoading(true);
      if (
        "userId" in JSON.parse(localStorage.getItem("user")) &&
        comment?.trim() !== ""
      ) {
        await postComment(
          comment,
          tweetId,
          JSON.parse(localStorage.getItem("user")).userId
        );
        props.setReload(!props.reload);
        setComment("");
      } else {
        alert("The comment was not posted.");
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      alert(
        "There was a problem while posting that comment, please refresh and try again."
      );
    }
  };

  const upvote = async () => {
    try {
      if (!props.tweet.id || !JSON.parse(localStorage.getItem("user")).userId)
        return;
      setDisabled(true);
      await upvoteTweet(
        props.tweet.id,
        JSON.parse(localStorage.getItem("user")).userId
      );
      props.setReload(!props.reload);
      setDisabled(false);
    } catch (e) {
      console.log("upvote tweet error:", e);
      alert(
        "There was an error while casting the vote. Please refresh and try again."
      );
      setDisabled(false);
    }
  };

  const downvote = async () => {
    try {
      if (!props.tweet.id || !JSON.parse(localStorage.getItem("user")).userId)
        return;
      setDisabled(true);
      await downvoteTweet(
        props.tweet.id,
        JSON.parse(localStorage.getItem("user")).userId
      );
      props.setReload(!props.reload);
      setDisabled(false);
    } catch (e) {
      console.log("downvote tweet error:", e);
      alert(
        "There was an error while casting the vote. Please refresh and try again."
      );
      setDisabled(false);
    }
  };

  return (
    <Card
      sx={{
        boxShadow: 0,
        borderRadius: 4,
        marginBottom: 2,
        maxWidth: "-webkit-fill-available",
        marginX: "auto",
      }}
    >
      <CardHeader
        titleTypographyProps={{
          fontWeight: "bold",
        }}
        subheaderTypographyProps={{
          textTransform: "uppercase",
          fontSize: "0.65rem",
        }}
        avatar={
          props?.tweet?.userIcon ? (
            <Avatar aria-label="userIcon">
              <img src={props?.tweet?.userIcon} alt="user" />
            </Avatar>
          ) : (
            <Avatar aria-label="userIcon"></Avatar>
          )
        }
        action={
          userExists && (
            <Box
              sx={{
                flexGrow: 1,
                justifyContent: "end",
              }}
            >
              <IconButton
                size="small"
                aria-label="menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <FontAwesomeIcon icon={faEllipsisV} />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
              >
                <Typography
                  px={2}
                  variant="body2"
                  component="p"
                  color="text.primary"
                >
                  Report
                </Typography>
              </Menu>
            </Box>
          )
        }
        title={
          <TwitterUserDetails
            userName={props?.tweet?.userName}
            userHandle={props?.tweet?.userHandle}
          />
        }
        subheader={new Date(props?.tweet?.tweetCreatedAt).toLocaleDateString(
          undefined,
          appConstants.DATE_FORMAT
        )}
      />
      <CardContent>
        <Typography variant="h4" component="p" color="text.primary">
          {props?.tweet?.body}
        </Typography>
      </CardContent>
      <Box
        px={2}
        sx={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        {props?.tweet?.prediction?.predictions ? (
          <>
            {props?.tweet?.prediction?.predictions?.severe_toxic >
              appConstants.TOXIC_THRESHOLD && (
              <ToxicityLabel
                type={appConstants.PREDICTIONS["SEVERE_TOXIC"].key}
              />
            )}
            {props?.tweet?.prediction?.predictions?.threat >
              appConstants.TOXIC_THRESHOLD && (
              <ToxicityLabel type={appConstants.PREDICTIONS["THREAT"].key} />
            )}
            {props?.tweet?.prediction?.predictions?.insult >
              appConstants.TOXIC_THRESHOLD && (
              <ToxicityLabel type={appConstants.PREDICTIONS["INSULT"].key} />
            )}
            {props?.tweet?.prediction?.predictions?.toxic >
              appConstants.TOXIC_THRESHOLD && (
              <ToxicityLabel type={appConstants.PREDICTIONS["TOXIC"].key} />
            )}
            {props?.tweet?.prediction?.predictions?.identity_hate >
              appConstants.TOXIC_THRESHOLD && (
              <ToxicityLabel
                type={appConstants.PREDICTIONS["IDENTITY_HATE"].key}
              />
            )}
            {props?.tweet?.prediction?.predictions?.obscene >
              appConstants.TOXIC_THRESHOLD && (
              <ToxicityLabel type={appConstants.PREDICTIONS["OBSCENE"].key} />
            )}
          </>
        ) : (
          ""
        )}
      </Box>
      {userExists && (
        <Box sx={{ px: 2, display: "flex", justifyContent: "between" }}>
          <input
            type="text"
            className="custom-comment-input"
            name="comment"
            value={comment}
            onChange={handleCommentChange}
            placeholder="Add a comment"
          ></input>
          {loading ? (
            <CircularProgress size={15} sx={{ alignSelf: "center", mx: 1 }} />
          ) : (
            <IconButton
              onClick={() => handleCommentPost(props.tweet.id)}
              aria-label="share"
              sx={{ alignSelf: "center" }}
            >
              <FontAwesomeIcon
                className="icon-size-large"
                icon={faPaperPlane}
              />
            </IconButton>
          )}
        </Box>
      )}
      {userExists ? (
        <>
          <CardActions p={0}>
            <IconButton
              size="small"
              aria-label="upvote"
              onClick={upvote}
              disabled={disabled}
            >
              <FontAwesomeIcon icon={faArrowUp} />
              <Typography ml={1} variant="body1">
                {props?.tweet?.upvotes ? props?.tweet?.upvotes?.length : 0}
              </Typography>
            </IconButton>
            <IconButton
              size="small"
              aria-label="downvote"
              onClick={downvote}
              disabled={disabled}
            >
              <FontAwesomeIcon icon={faArrowDown} />
              <Typography ml={1} variant="body1">
                {props?.tweet?.downvotes ? props?.tweet?.downvotes?.length : 0}
              </Typography>
            </IconButton>
            <IconButton aria-label="comment" size="small">
              <FontAwesomeIcon icon={faComment} />
              <Typography ml={1} variant="body1">
                {props?.tweet?.comments ? props?.tweet?.comments?.length : 0}
              </Typography>
            </IconButton>
          </CardActions>
          {props?.tweet?.comments?.length > 0 && (
            <CardContent sx={{ paddingTop: 0 }}>
              <Box
                className={
                  JSON.parse(localStorage.getItem("user")).userId ===
                  props?.tweet?.comments[0]?.userId
                    ? "hover-visible"
                    : ""
                }
                variant="body2"
              >
                <Typography
                  sx={{ display: "block" }}
                  mr={1}
                  variant="span"
                  fontWeight="bold"
                >
                  {props?.tweet?.comments[0]?.userHandle}
                  <Typography
                    color="text.disabled"
                    fontWeight="normal"
                    className="timestamp-size"
                    sx={{ ml: 0.5 }}
                    variant="span"
                  >
                    {getTimeAgo(props?.tweet?.comments[0]?.commentCreatedAt)}
                  </Typography>
                </Typography>
                <Typography sx={{ display: "inline" }} mr={1} variant="body2">
                  {props?.tweet?.comments[0]?.body}
                </Typography>
                <Typography
                  color="text.disabled"
                  className="pointer"
                  sx={{ ml: 0.5, visibility: "hidden" }}
                  variant="span"
                >
                  <EditCommentModal
                    reload={props.reload}
                    setReload={props.setReload}
                    tweetId={props?.tweet?.id}
                    commentText={props?.tweet?.comments[0]?.body}
                    commentId={props?.tweet?.comments[0]?.commentId}
                    userId={props?.tweet?.comments[0]?.userId}
                  />
                </Typography>
                {props?.tweet?.comments?.length - 1 > 0 && (
                  <Typography
                    color="text.disabled"
                    ml={1}
                    variant="span"
                    onClick={seeAllComments}
                    className="pointer"
                    sx={{ float: "right" }}
                  >
                    {props?.tweet?.comments?.length - 1} more{" "}
                    <ExpandMore
                      expand={expanded}
                      onClick={seeAllComments}
                      aria-expanded={expanded}
                      aria-label="show more"
                      sx={{ p: 0, color: "inherit" }}
                    >
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className="icon-size-large"
                      />
                    </ExpandMore>
                  </Typography>
                )}
              </Box>
              {props?.tweet?.comments?.length - 1 > 0 && (
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  {props?.tweet?.comments?.map((comment, index) => {
                    return (
                      index !== 0 && (
                        <Box
                          key={index}
                          className={
                            JSON.parse(localStorage.getItem("user")).userId ===
                            comment?.userId
                              ? "hover-visible"
                              : ""
                          }
                          variant="body2"
                        >
                          <Typography
                            sx={{ display: "block" }}
                            mr={1}
                            variant="span"
                            fontWeight="bold"
                          >
                            {comment?.userHandle}
                            <Typography
                              color="text.disabled"
                              fontWeight="normal"
                              className="timestamp-size"
                              sx={{ ml: 0.5 }}
                              variant="span"
                            >
                              {getTimeAgo(comment?.commentCreatedAt)}
                            </Typography>
                          </Typography>
                          <Typography
                            sx={{ display: "inline" }}
                            mr={1}
                            variant="body2"
                          >
                            {comment?.body}
                          </Typography>
                          <Typography
                            color="text.disabled"
                            className="pointer"
                            sx={{ ml: 0.5, visibility: "hidden" }}
                            variant="span"
                          >
                            <EditCommentModal
                              reload={props.reload}
                              setReload={props.setReload}
                              tweetId={props?.tweet?.id}
                              commentText={comment?.body}
                              commentId={comment?.commentId}
                              userId={comment?.userId}
                            />
                          </Typography>
                        </Box>
                      )
                    );
                  })}
                </Collapse>
              )}
            </CardContent>
          )}
        </>
      ) : (
        <Box p={1}>
          <IconButton aria-label="upvote" size="small">
            <FontAwesomeIcon icon={faArrowUp} />
            <Typography ml={1} variant="body1">
              {props?.tweet?.upvotes ? props?.tweet?.upvotes?.length : 0}
            </Typography>
          </IconButton>
          <IconButton aria-label="downvote" size="small">
            <FontAwesomeIcon icon={faArrowDown} />
            <Typography ml={1} variant="body1">
              {props?.tweet?.downvotes ? props?.tweet?.downvotes?.length : 0}
            </Typography>
          </IconButton>
        </Box>
      )}
    </Card>
  );
}
