import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import appConstants from "../utils/appConstants";
import { hideComments, unhideComments } from "../firebaseFunctions/tweets";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUp,
  faArrowDown,
  faSearch,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";

export default function AdminTweetDetails(props) {
  const [commentHideLoading, setCommentHideLoading] = React.useState(false);
  const [query, setQuery] = React.useState("");

  const onQueryChange = (e) => {
    setQuery(e.target.value);
  };

  const onHideComment = async (commentId) => {
    try {
      if (!commentId) return;
      setCommentHideLoading(true);
      await hideComments([commentId]);
      setCommentHideLoading(false);
      props.setReload(!props.reload);
    } catch (e) {
      console.log("hide comment error:", e);
      alert(
        "There was an error while hiding this comment. Please refresh and try again."
      );
      setCommentHideLoading(false);
    }
  };

  const onUnhideComment = async (commentId) => {
    try {
      if (!commentId) return;
      setCommentHideLoading(true);
      await unhideComments([commentId]);
      setCommentHideLoading(false);
      props.setReload(!props.reload);
    } catch (e) {
      console.log("unhide comment error:", e);
      alert(
        "There was an error while unhiding this comment. Please refresh and try again."
      );
      setCommentHideLoading(false);
    }
  };

  return (
    <Card
      sx={{
        boxShadow: 0,
        borderRadius: 4,
        marginBottom: 2,
        maxWidth: "-webkit-fill-available",
        marginX: "auto",
      }}
    >
      <CardHeader
        titleTypographyProps={{
          fontWeight: "bold",
        }}
        subheaderTypographyProps={{
          textTransform: "uppercase",
          fontSize: "0.65rem",
        }}
        avatar={
          <Avatar aria-label="userIcon">
            {/* <img src={props?.tweet?.userIcon} alt="user" /> */}
          </Avatar>
        }
        action={
          <Box
            sx={{
              flexGrow: 1,
              justifyContent: "end",
            }}
          >
            <IconButton aria-label="upvote">
              <FontAwesomeIcon icon={faArrowUp} className="icon-size-large" />
              <Typography ml={1} variant="body1">
                {props?.tweet?.upvotes ? props?.tweet?.upvotes?.length : 0}
              </Typography>
            </IconButton>
            <IconButton aria-label="downvote">
              <FontAwesomeIcon icon={faArrowDown} className="icon-size-large" />
              <Typography ml={1} variant="body1">
                {props?.tweet?.downvotes ? props?.tweet?.downvotes?.length : 0}
              </Typography>
            </IconButton>
          </Box>
        }
        title={props?.tweet?.userHandle}
        subheader={new Date(props?.tweet?.tweetCreatedAt).toLocaleDateString(
          undefined,
          appConstants.DATE_FORMAT
        )}
      />
      <CardContent sx={{ paddingY: 1 }}>
        <Typography variant="h4" px={2} component="p" color="text.primary">
          {props?.tweet?.body}
        </Typography>
      </CardContent>

      <CardActions p={0}></CardActions>
      {props?.tweet?.comments?.length > 0 && (
        <CardContent sx={{ paddingTop: 0 }}>
          <Box
            sx={{
              borderRadius: "25px",
              paddingX: 1.5,
              mb: 2,
              mx: "auto",
              display: "flex",
              justifyContent: "between",
              alignItems: "center",
            }}
          >
            <label className="custom-input" htmlFor="search-query">
              <FontAwesomeIcon
                icon={faSearch}
                className="icon-size-large custom-search-icon"
              />
              <input
                type="text"
                id="search-query"
                placeholder="Search Comments..."
                value={query}
                onChange={onQueryChange}
              ></input>
            </label>
          </Box>
          <Box>
            {props?.tweet?.comments
              ?.filter((comment) => {
                if (query === "") return comment;
                else if (
                  comment.body.toLowerCase().includes(query.toLowerCase())
                ) {
                  return comment;
                }
              })
              ?.map((comment, index) => {
                return (
                  <Typography
                    sx={{ py: 1, px: 2, borderRadius: 5 }}
                    className="hover-bg"
                    variant="body2"
                    key={index}
                  >
                    <Typography
                      mr={1}
                      variant="span"
                      fontWeight="bold"
                      color={comment?.hidden ? "text.disabled" : "text.primary"}
                    >
                      {comment?.userHandle}
                    </Typography>
                    <Typography
                      component="span"
                      variant="span"
                      color={comment?.hidden ? "text.disabled" : "text.primary"}
                    >
                      {comment?.body}
                    </Typography>
                    {commentHideLoading ? (
                      <Typography
                        className="pointer icon-size-medium"
                        sx={{
                          float: "right",
                          ml: 1,
                          mt: 0.3,
                          visibility: "hidden",
                        }}
                        variant="span"
                        color="text.disabled"
                      >
                        {comment?.hidden ? (
                          <FontAwesomeIcon
                            icon={faEye}
                            className="icon-size-medium"
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faEyeSlash}
                            className="icon-size-medium"
                          />
                        )}
                      </Typography>
                    ) : (
                      <Typography
                        className="pointer icon-size-medium"
                        sx={{
                          float: "right",
                          ml: 1,
                          visibility: "hidden",
                          mt: 0.3,
                        }}
                        variant="span"
                        onClick={() => {
                          comment?.hidden
                            ? onUnhideComment(comment.commentId)
                            : onHideComment(comment.commentId);
                        }}
                      >
                        {comment?.hidden ? (
                          <FontAwesomeIcon
                            icon={faEye}
                            className="icon-size-medium"
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faEyeSlash}
                            className="icon-size-medium"
                          />
                        )}
                      </Typography>
                    )}
                  </Typography>
                );
              })}
          </Box>
        </CardContent>
      )}
    </Card>
  );
}
