import { fetchTweets } from "../firebaseFunctions/tweets";

const calculateLeaderboard = async () => {
  try {
    var tweets = await fetchTweets();
    var leaderboard = [];
    for (let tweet of tweets) {
      if (tweet?.upvotes?.length > 0 && !tweet?.archived) {
        let s = 0;
        if (tweet.userId in leaderboard) {
          s = leaderboard[`${tweet.userId}`].score;
        }
        leaderboard[`${tweet.userId}`] = {
          userId: tweet.userId,
          score: s + 1,
          name: tweet.userName,
          handle: tweet.userHandle,
          userIcon: tweet.userIcon,
        };
      }
    }
    // converting leaderboard from an array with
    // string indices to an array of numerical indices
    // since array functions don't work on it otherwise
    var leaderboardArray = Object.values(leaderboard);
    // sorting based on score
    leaderboardArray.sort((a, b) => (a.score < b.score ? 1 : -1));
    // console.log(leaderboardArray);
    return leaderboardArray;
  } catch (e) {
    console.log("calculate leaderboard error:", e);
    return [];
  }
};

export { calculateLeaderboard };
