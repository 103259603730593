import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
// import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
// import Avatar from "@mui/material/Avatar";
// import Button from "@mui/material/Button";
// import Tooltip from "@mui/material/Tooltip";
// import MenuItem from "@mui/material/MenuItem";
import LoginModal from "./LoginModal";
import LogoutButton from "./LogoutButton";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

// const pages = ["test"];
// const settings = ["Profile", "Logout"];

const Navbar = () => {
  const [userData, setUserData] = React.useState({});
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  // const [anchorElUser, setAnchorElUser] = React.useState(null);

  React.useEffect(() => {
    // console.log("use effect");
    const userData = JSON.parse(localStorage.getItem("user"));
    setUserData((prevUserData) => ({
      ...prevUserData,
      ...userData,
    }));
  }, []);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  // const handleOpenUserMenu = (event) => {
  //   setAnchorElUser(event.currentTarget);
  // };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  // const handleCloseUserMenu = () => {
  //   setAnchorElUser(null);
  // };

  return (
    <>
      <AppBar
        position="fixed"
        color="inherit"
        sx={{ boxShadow: 0 }}
        //   sx={{ boxShadow: "2px 5px 12px #ccc" }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <a href="/">
              <img
                alt="logo"
                src={require("../images/techflo-main.png")}
                style={{ maxHeight: "33px" }}
              />
            </a>
            {/* toggle menu - mobile & tablet */}
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "flex", md: "none" },
                justifyContent: "end",
              }}
            >
              <IconButton
                size="small"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <FontAwesomeIcon icon={faBars} />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {/* {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography color="text.secondary" textAlign="center">
                    {page}
                  </Typography>
                </MenuItem>
              ))} */}
                {userData?.email?.length > 0 ? (
                  <LogoutButton />
                ) : (
                  <LoginModal />
                )}
              </Menu>
            </Box>
            {/* main menu - desktop */}
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                justifyContent: "end",
              }}
            >
              {/* {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "text.secondary", display: "block" }}
              >
                {page}
              </Button>
            ))} */}
              {userData?.email?.length > 0 ? <LogoutButton /> : <LoginModal />}
            </Box>
            {/* profile and settings dropdown */}
            <Box sx={{ flexGrow: 0 }}>
              {/*  <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                  <Avatar aria-label="userIcon"></Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu> */}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <div style={{ height: 85 }}></div>
    </>
  );
};
export default Navbar;
