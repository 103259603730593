import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import CircularProgress from "@mui/material/CircularProgress";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  deleteUser,
} from "firebase/auth";
import { createNewUser } from "../firebaseFunctions/users";
import { extractAuthErrorMessage } from "../utils/helpers";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "450px",
  width: "65%",
  bgcolor: "background.paper",
  border: 0,
  borderRadius: 5,
  p: 4,
};

const loginButtonStyle = {
  color: "text.secondary",
  textTransform: "none",
};

export default function BasicModal(props) {
  const auth = getAuth();
  const [open, setOpen] = React.useState(false);
  const [register, setRegister] = React.useState(false);
  const [state, setState] = React.useState({
    userHandle: "",
    email: "",
    password: "",
    confirmPassword: "",
    errorMsg: "",
    loading: false,
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onInputChange = (e) => {
    if (state.loading) return;
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
      errorMsg: "",
    }));
  };

  const registerUser = () => {
    if (state.password !== state.confirmPassword) {
      setState((prevState) => ({
        ...prevState,
        errorMsg: "Passwords don't match.",
      }));
      return;
    }
    if (state.userHandle.trim() === "") {
      setState((prevState) => ({
        ...prevState,
        errorMsg: "Enter a user handle.",
      }));
      return;
    }
    setState((prevState) => ({
      ...prevState,
      loading: true,
    }));
    createUserWithEmailAndPassword(auth, state.email, state.password)
      .then((regUser) => {
        const userData = {
          userHandle: state.userHandle,
          email: state.email,
          createdAt: regUser.user.metadata.createdAt,
        };
        createNewUser(userData, regUser.user.uid)
          .then(() => {
            loginUser();
          })
          .catch((err) => {
            deleteUser(auth.currentUser).catch((error) => {
              console.log("delete user error:", error);
            });
            setState((prevState) => ({
              ...prevState,
              errorMsg:
                "There was an error while creating the user, please refresh and try again.",
              loading: false,
            }));
            console.log("create user error:", err);
          });
      })
      .catch((err) => {
        const errText = extractAuthErrorMessage(err.message);
        setState((prevState) => ({
          ...prevState,
          errorMsg: errText,
          loading: false,
        }));
        console.log("register error:", err);
      });
  };

  const loginUser = () => {
    setState((prevState) => ({
      ...prevState,
      loading: true,
    }));
    signInWithEmailAndPassword(auth, state.email, state.password)
      .then((user) => {
        // console.log(user);
        setTimeout(() => {
          if (
            JSON.parse(localStorage.getItem("user")) &&
            "isAdmin" in JSON.parse(localStorage.getItem("user")) &&
            JSON.parse(localStorage.getItem("user")).isAdmin
          ) {
            window.location = "/admin";
          } else {
            window.location.reload();
          }
        }, 1500);
      })
      .catch((err) => {
        const errText = extractAuthErrorMessage(err.message);
        setState((prevState) => ({
          ...prevState,
          errorMsg: errText,
          loading: false,
        }));
        console.log("login error:", err);
      });
  };

  return (
    <div style={{ display: "flex" }}>
      <Button sx={loginButtonStyle} onClick={handleOpen}>
        <Typography>Login</Typography>
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h3"
            align="center"
          >
            {register ? "Register" : "Login"}
          </Typography>
          <Box sx={{ mt: 4 }}>
            {register && (
              <TextField
                name="userHandle"
                value={state.userHandle}
                onChange={onInputChange}
                sx={{ mb: 3 }}
                fullWidth={true}
                label="User Handle"
                type="text"
              />
            )}
            <TextField
              name="email"
              value={state.email}
              onChange={onInputChange}
              sx={{ mb: 3 }}
              fullWidth={true}
              label="Email"
              type="text"
            />
            <TextField
              name="password"
              value={state.password}
              onChange={onInputChange}
              sx={{ mb: 3 }}
              fullWidth={true}
              label="Password"
              type="password"
            />
            {register && (
              <TextField
                name="confirmPassword"
                value={state.confirmPassword}
                onChange={onInputChange}
                sx={{ mb: 3 }}
                fullWidth={true}
                label="Re-Type Password"
                type="password"
              />
            )}
            {register ? (
              <Typography
                variant="body1"
                id="modal-modal-title"
                align="center"
                mb={1}
              >
                Already have an account?
                <Typography
                  mx={0.5}
                  className="pointer"
                  variant="span"
                  color="primary"
                  onClick={() => {
                    setRegister(false);
                  }}
                >
                  Login
                </Typography>
                instead.
              </Typography>
            ) : (
              <Typography
                variant="body1"
                id="modal-modal-title"
                align="center"
                mb={3}
              >
                No account?
                <Typography
                  mx={0.5}
                  className="pointer"
                  variant="span"
                  color="primary"
                  onClick={() => {
                    setRegister(true);
                  }}
                >
                  Register
                </Typography>
                instead.
              </Typography>
            )}
            <Typography align="center" mb={3} variant="body2" color="error">
              {state.errorMsg}
            </Typography>
            <Container sx={{ display: "flex", justifyContent: "center" }}>
              {register ? (
                state.loading ? (
                  <CircularProgress />
                ) : (
                  <Button variant="contained" onClick={registerUser}>
                    Register
                  </Button>
                )
              ) : state.loading ? (
                <CircularProgress />
              ) : (
                <Button variant="contained" onClick={loginUser}>
                  Login
                </Button>
              )}
            </Container>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
