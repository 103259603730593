import { useEffect, useState } from "react";
import Tweet from "../components/Tweet";
import {
  Grid,
  Box,
  Button,
  IconButton,
  CircularProgress,
  Container,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import AboutCobot from "../sections/AboutCobot";
import Leaderboard from "../sections/Leaderboard";
import Navbar from "../components/Navbar";
// import Footer from "../components/Footer";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortAmountAsc, faSearch } from "@fortawesome/free-solid-svg-icons";

import { fetchTweetsWithoutHiddenComments } from "../firebaseFunctions/tweets";
import appConstants from "../utils/appConstants";

function Tweets() {
  const [state, setState] = useState({
    loading: true,
    loadingTweets: false,
    tweets: [],
    query: "",
    tweetPage: 1,
    fallbackTweetId: "",
  });
  const [reload, setReload] = useState(false);
  const [anchorElFilter, setAnchorElFilter] = useState(null);

  const handleOpenFilterMenu = (event) => {
    setAnchorElFilter(event.currentTarget);
  };
  const handleCloseFilterMenu = () => {
    setAnchorElFilter(null);
  };

  useEffect(() => {
    const fetchTweets = async () => {
      const tweets = await fetchTweetsWithoutHiddenComments(
        appConstants.TWEETS_PER_PAGE
      );
      // const tweets = appConstants.TEST_DATA;
      // console.log(tweets);
      setState((prevState) => ({
        ...prevState,
        tweets: tweets,
        fallbackTweetId: tweets[0].id ? tweets[0].id : "",
        loading: false,
      }));
    };
    fetchTweets();
  }, [reload]);

  const onQueryChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      query: e.target.value,
    }));
  };

  const sortTweetsByVotes = (asc = false) => {
    let sortedTweets = state.tweets,
      tweets = state.tweets;
    handleCloseFilterMenu();
    // sort in ascending order
    if (asc) {
      sortedTweets = tweets.sort((a, b) => {
        let length1 = a?.upvotes?.length ? a?.upvotes?.length : 0;
        let length2 = b?.upvotes?.length ? b?.upvotes?.length : 0;
        return length1 > length2 ? 1 : -1;
      });
      setState((prevState) => ({
        ...prevState,
        tweets: sortedTweets,
      }));
    }
    // sort in descending order
    else if (!asc) {
      sortedTweets = tweets.sort((a, b) => {
        let length1 = a?.upvotes?.length ? a?.upvotes?.length : 0;
        let length2 = b?.upvotes?.length ? b?.upvotes?.length : 0;
        return length1 < length2 ? 1 : -1;
      });
      setState((prevState) => ({
        ...prevState,
        tweets: sortedTweets,
      }));
    }
    // do nothing if order isn't specified
  };

  const sortTweetsByDate = (asc = false) => {
    let sortedTweets = state.tweets,
      tweets = state.tweets;
    handleCloseFilterMenu();
    // sort in ascending order
    if (asc) {
      sortedTweets = tweets.sort((a, b) => {
        let date1 = new Date(a?.tweetCreatedAt);
        let date2 = new Date(b?.tweetCreatedAt);
        return date1 > date2 ? 1 : -1;
      });
      setState((prevState) => ({
        ...prevState,
        tweets: sortedTweets,
      }));
    }
    // sort in descending order
    else if (!asc) {
      sortedTweets = tweets.sort((a, b) => {
        let date1 = new Date(a?.tweetCreatedAt);
        let date2 = new Date(b?.tweetCreatedAt);
        return date1 < date2 ? 1 : -1;
      });
      setState((prevState) => ({
        ...prevState,
        tweets: sortedTweets,
      }));
    }
    // do nothing if order isn't specified
  };

  const fetchMoreTweets = async (next = true) => {
    try {
      let page = state.tweetPage;
      setState((prevState) => ({
        ...prevState,
        loadingTweets: true,
      }));
      // fetch previous tweets
      if (!next) {
        const tweetId = state.tweets[appConstants.TWEETS_PER_PAGE - 1]?.id
          ? state.tweets[appConstants.TWEETS_PER_PAGE - 1].id
          : state.fallbackTweetId;
        const tweets = await fetchTweetsWithoutHiddenComments(
          appConstants.TWEETS_PER_PAGE,
          tweetId,
          false
        );
        setState((prevState) => ({
          ...prevState,
          tweets: tweets,
          loadingTweets: false,
          tweetPage: page - 1,
        }));
      }
      // fetch next tweets
      else {
        const tweetId = state.tweets[appConstants.TWEETS_PER_PAGE - 1]?.id
          ? state.tweets[appConstants.TWEETS_PER_PAGE - 1].id
          : state.fallbackTweetId;
        const tweets = await fetchTweetsWithoutHiddenComments(
          appConstants.TWEETS_PER_PAGE,
          tweetId,
          true
        );
        setState((prevState) => ({
          ...prevState,
          tweets: tweets,
          loadingTweets: false,
          tweetPage: page + 1,
        }));
      }
    } catch (e) {
      console.log("fetch more tweets error:", e);
      setState((prevState) => ({
        ...prevState,
        loadingTweets: false,
      }));
    }
  };

  return (
    <>
      <Navbar />
      {state.loading ? (
        <Container
          sx={{ display: "flex", justifyContent: "center", paddingTop: "36vh" }}
        >
          <CircularProgress />
        </Container>
      ) : state.tweets?.length > 0 ? (
        <>
          <Box sx={{ flexGrow: 1, paddingX: 1 }}>
            <Grid container sx={{ justifyContent: "center" }}>
              {/* about cobot - desktop */}
              <Grid
                item
                xs={12}
                sm={9}
                md={3}
                sx={{
                  display: {
                    xs: "none",
                    md: "block",
                    position: "fixed",
                    left: 0,
                    width: "33%",
                  },
                }}
              >
                <Box sx={{ paddingX: 1 }}>
                  <AboutCobot expanded={true} />
                </Box>
              </Grid>
              {/* about cobot - mobile & tablet */}
              <Grid
                item
                xs={12}
                sm={9}
                md={3}
                sx={{ display: { xs: "block", md: "none" } }}
              >
                <Box sx={{ paddingX: 1 }}>
                  <AboutCobot expanded={false} />
                </Box>
              </Grid>
              {/* leaderboard - mobile & tablet */}
              <Grid
                item
                xs={12}
                sm={9}
                md={3}
                sx={{ display: { xs: "block", md: "none" } }}
              >
                <Box sx={{ paddingX: 1 }}>
                  <Leaderboard reload={reload} expanded={false} />
                </Box>
              </Grid>
              {/* tweets & search */}
              <Grid item xs={12} sm={9} md={6}>
                <Grid container sx={{ justifyContent: "center" }}>
                  {/* search and sort start */}
                  <Grid item xs={12} sx={{ px: 0.7 }}>
                    <Box
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "25px",
                        padding: 2,
                        mb: 2,
                        mx: "auto",
                        display: "flex",
                        justifyContent: "between",
                        alignItems: "center",
                      }}
                    >
                      <label
                        className="custom-search-bar"
                        htmlFor="search-query"
                      >
                        <FontAwesomeIcon
                          icon={faSearch}
                          className="custom-search-icon"
                        />
                        <input
                          type="text"
                          id="search-query"
                          placeholder="Search Tweets..."
                          value={state.query}
                          onChange={onQueryChange}
                        ></input>
                      </label>
                      <Box
                        sx={{
                          flexGrow: 1,
                          justifyContent: "end",
                          padding: 0,
                        }}
                      >
                        <IconButton
                          size="small"
                          aria-label="filter"
                          aria-controls="menu-appbar"
                          aria-haspopup="true"
                          onClick={handleOpenFilterMenu}
                          color="inherit"
                          sx={{ p: 0 }}
                        >
                          <FontAwesomeIcon icon={faSortAmountAsc} />
                        </IconButton>
                        <Menu
                          id="menu-appbar"
                          anchorEl={anchorElFilter}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          keepMounted
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          open={Boolean(anchorElFilter)}
                          onClose={handleCloseFilterMenu}
                        >
                          <MenuItem
                            onClick={() => {
                              sortTweetsByDate(true);
                            }}
                          >
                            <Typography
                              color="text.secondary"
                              textAlign="center"
                            >
                              By date (ascending)
                            </Typography>
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              sortTweetsByDate(false);
                            }}
                          >
                            <Typography
                              color="text.secondary"
                              textAlign="center"
                            >
                              By date (descending)
                            </Typography>
                          </MenuItem>
                          <div className="custom-hr-sm"></div>
                          <MenuItem
                            onClick={() => {
                              sortTweetsByVotes(true);
                            }}
                          >
                            <Typography
                              color="text.secondary"
                              textAlign="center"
                            >
                              By most upvotes (ascending)
                            </Typography>
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              sortTweetsByVotes(false);
                            }}
                          >
                            <Typography
                              color="text.secondary"
                              textAlign="center"
                            >
                              By most upvotes (descending)
                            </Typography>
                          </MenuItem>
                        </Menu>
                      </Box>
                    </Box>
                  </Grid>
                  {/* search and sort end */}
                  {/* tweeets start */}
                  {state.loadingTweets ? (
                    <Container
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: "36vh",
                      }}
                    >
                      <CircularProgress />
                    </Container>
                  ) : (
                    <>
                      <Grid item xs={12}>
                        <Box sx={{ paddingX: 1 }}>
                          {state.tweets
                            ?.filter((item) => !item?.archived)
                            ?.filter((item) => {
                              if (state.query === "") return item;
                              else if (
                                item.body
                                  .toLowerCase()
                                  .includes(state.query.toLowerCase())
                              ) {
                                return item;
                              }
                            })
                            ?.map((item, id) => {
                              return (
                                <Tweet
                                  reload={reload}
                                  setReload={setReload}
                                  key={id}
                                  tweet={item}
                                />
                              );
                            })}
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box>
                          {/* fetch previous tweets */}
                          {state.tweetPage > 1 && (
                            <Button
                              sx={{ float: "left", mb: 2, color: "#888" }}
                              onClick={() => {
                                fetchMoreTweets(false);
                              }}
                            >
                              &lt; prev
                            </Button>
                          )}
                          {/* fetch next tweets  */}
                          <Button
                            sx={{ float: "right", mb: 2, color: "#888" }}
                            onClick={() => {
                              fetchMoreTweets(true);
                            }}
                          >
                            next &gt;
                          </Button>
                        </Box>
                      </Grid>
                    </>
                  )}
                  {/* tweets end */}
                </Grid>
              </Grid>
              {/* leaderboard - desktop */}
              <Grid
                item
                xs={12}
                sm={9}
                md={3}
                sx={{
                  display: {
                    xs: "none",
                    md: "block",
                    position: "fixed",
                    right: 0,
                    width: "33%",
                  },
                }}
              >
                <Box sx={{ paddingX: 1 }}>
                  <Leaderboard reload={reload} expanded={true} />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </>
      ) : (
        <Container sx={{ display: "flex", justifyContent: "center" }}>
          <Typography mt={1} variant="h4" component="p">
            No Tweets
          </Typography>
        </Container>
      )}
      {/* <Footer /> */}
    </>
  );
}

export default Tweets;
