import { setDoc, getDoc, doc } from "firebase/firestore";
import { db } from "./config";

const createNewUser = async (userData, userId) => {
  try {
    return await setDoc(doc(db, "users", userId), userData);
  } catch (error) {
    console.error("create new user error:", error);
    return await Promise.reject(error);
  }
};

const getUserData = async (uid) => {
  try {
    const userDoc = await getDoc(doc(db, "users", uid));
    if (userDoc.exists()) {
      return { userId: userDoc.id, ...userDoc.data() };
    } else {
      return false;
    }
  } catch (error) {
    console.error("get user data error:", error);
    return await Promise.reject(error);
  }
};

export { createNewUser, getUserData };
