import { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Grid,
  Collapse,
  IconButton,
  Typography,
  Container,
} from "@mui/material";
import LeaderboardItem from "../components/LeaderboardItem";
import { calculateLeaderboard } from "../utils/leaderboard";
import CircularProgress from "@mui/material/CircularProgress";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import { styled } from "@mui/material/styles";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginTop: !expand ? "5px" : "-5px",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.short,
  }),
}));

export default function Leaderboard(props) {
  const [leaderboardArray, setLeaderboardArray] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [seeAll, setSeeAll] = useState(false);

  useEffect(() => {
    setExpanded(props.expanded);
    const getLeaderboardArray = async () => {
      try {
        setLoading(true);
        const items = await calculateLeaderboard();
        setLeaderboardArray(items);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.log("get leader board error:", e);
      }
    };
    getLeaderboardArray();
  }, [props.reload]);

  return (
    <Card
      sx={{
        boxShadow: 0,
        borderRadius: 4,
        marginBottom: 2,
        maxWidth: "-webkit-fill-available",
        marginX: "auto",
      }}
    >
      <CardContent>
        <Typography mt={1} variant="h5" component="h2" color="text.primary">
          Leaderboard
          <ExpandMore
            expand={expanded}
            onClick={() => {
              setExpanded(!expanded);
            }}
            aria-expanded={expanded}
            aria-label="show more"
            sx={{
              p: 0,
              color: "inherit",
              float: "right",
              marginTop: 1,
            }}
          >
            <FontAwesomeIcon className="icon-size-large" icon={faChevronDown} />
          </ExpandMore>
        </Typography>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          {/* leaderboard table header start */}
          <Grid container wrap="nowrap" sx={{ marginTop: 3 }} spacing={1}>
            <Grid item xs={2}>
              <Typography
                fontWeight="900"
                variant="body2"
                component="p"
                color="text.disabled"
              >
                #
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography
                noWrap={true}
                fontWeight="900"
                variant="body2"
                component="p"
                color="text.disabled"
              >
                User Name
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                fontWeight="900"
                variant="body2"
                component="p"
                color="text.disabled"
              >
                Score
              </Typography>
            </Grid>
          </Grid>
          {/* leaderboard table header end */}

          {loading ? (
            <Container
              sx={{
                display: "flex",
                justifyContent: "center",
                paddingY: 10,
              }}
            >
              <CircularProgress />
            </Container>
          ) : leaderboardArray?.length > 0 ? (
            seeAll ? (
              <>
                {leaderboardArray?.map((item, id) => {
                  return (
                    <LeaderboardItem
                      key={id}
                      index={id}
                      leaderboardItem={item}
                    />
                  );
                })}
                <div className="custom-hr" />
                <Typography
                  className="pointer"
                  variant="body2"
                  color="text.disabled"
                  align="center"
                  onClick={() => {
                    setSeeAll(false);
                  }}
                >
                  Hide
                </Typography>
              </>
            ) : (
              <>
                {leaderboardArray[0] && (
                  <LeaderboardItem
                    index={0}
                    leaderboardItem={leaderboardArray[0]}
                  />
                )}
                {leaderboardArray[1] && (
                  <LeaderboardItem
                    index={1}
                    leaderboardItem={leaderboardArray[1]}
                  />
                )}
                {leaderboardArray[2] && (
                  <LeaderboardItem
                    index={2}
                    leaderboardItem={leaderboardArray[2]}
                  />
                )}
                <div className="custom-hr" />
                <Typography
                  className="pointer"
                  variant="body2"
                  color="text.disabled"
                  align="center"
                  onClick={() => {
                    setSeeAll(true);
                  }}
                >
                  See all
                </Typography>
              </>
            )
          ) : (
            <>
              <div className="custom-hr" />
              <Typography
                my={10}
                px={4}
                variant="body1"
                component="p"
                color="text.disabled"
                align="center"
              >
                (seems like theres no upvoted tweet yet!)
              </Typography>
            </>
          )}
        </Collapse>
      </CardContent>
    </Card>
  );
}
