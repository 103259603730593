import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import LogoutButton from "../components/LogoutButton";
import AdminTweets from "../sections/AdminTweets";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

const drawerWidth = 240;

function Admin(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const menuItems = [
    { name: "Tweets", icon: "fab fa-twitter" },
    { name: "Alerts", icon: "fas fa-bell" },
  ];

  const drawer = (
    <div>
      <Toolbar sx={{ display: { xs: "none", md: "flex" } }}>
        <a href="/">
          <img
            alt="logo"
            src={require("../images/techflo-main.png")}
            style={{ maxHeight: "33px" }}
          />
        </a>
      </Toolbar>
      <Divider />
      <List sx={{ paddingTop: 0 }}>
        {menuItems.map((item, index) => (
          <div key={index}>
            <ListItem button>
              <ListItemIcon sx={{ paddingLeft: "6px" }}>
                <i className={item.icon}></i>
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItem>
            <Divider />
          </div>
        ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
          boxShadow: "0",
          backgroundColor: "white",
          color: "black",
        }}
      >
        <Toolbar
          sx={{
            justifyContent: {
              lg: "flex-end",
              md: "flex-end",
              sm: "space-between",
              xs: "space-between",
            },
          }}
        >
          <IconButton
            size="small"
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <FontAwesomeIcon icon={faBars} />
          </IconButton>
          <Toolbar sx={{ display: { md: "none" } }}>
            <img
              alt="logo"
              src={require("../images/techflo-main.png")}
              style={{ maxHeight: "33px" }}
            />
          </Toolbar>
          <LogoutButton />
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{
          width: { md: drawerWidth },
          flexShrink: { md: 0 },
        }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { md: `calc(90% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        <AdminTweets />
      </Box>
    </Box>
  );
}

export default Admin;
