import React from "react";
import {
  Typography,
  Modal,
  Box,
  CircularProgress,
  Avatar,
} from "@mui/material";
import { fetchTwitterUser } from "../firebaseFunctions/tweets";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faLink,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "550px",
  minWidth: "250px",
  minHeight: "250px",
  width: "75%",
  bgcolor: "background.paper",
  border: 0,
  borderRadius: 5,
  p: 4,
};

export default function TwitterUserDetails(props) {
  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState({
    loading: true,
    user: {},
  });

  const fetchUser = async () => {
    const user = await fetchTwitterUser(props.userHandle);
    setState((prevState) => ({
      ...prevState,
      user: user,
      loading: false,
    }));
  };

  const handleOpen = () => {
    fetchUser();
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <span className="pointer" onClick={handleOpen}>
        {props.userName}
      </span>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          {state.loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20%",
              }}
            >
              <CircularProgress sx={{ marginY: 1, marginX: "auto" }} />
            </Box>
          ) : (
            <Box>
              {Object?.keys(state.user)?.length === 0 ? (
                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20%",
                  }}
                  fontWeight="bold"
                  color="error"
                >
                  <i
                    style={{ marginTop: "5px", marginRight: "5px" }}
                    className="fas fa-exclamation-circle"
                  ></i>{" "}
                  Couldn't fetch user information.
                </Typography>
              ) : (
                <>
                  <i
                    style={{ float: "right" }}
                    className="fas fa-times pointer"
                    onClick={handleClose}
                  ></i>
                  <Box sx={{ display: "flex", justifyContent: "left" }}>
                    <Avatar
                      alt={state.user?.userName}
                      src={state.user?.userAvatar}
                      sx={{
                        width: 56,
                        height: 56,
                        mr: 2,
                        border: "1px solid #ccc",
                      }}
                    />
                    {/* {props.userName} */}
                    <Box>
                      <Typography variant="h5">
                        {state.user?.userName}
                      </Typography>
                      <Typography variant="body1" color="text.disabled">
                        @{state.user?.userHandle}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="body1">
                      {state.user?.userBio}
                    </Typography>
                  </Box>
                  {state.user?.userLocation && (
                    <Box sx={{ mt: 2 }}>
                      <FontAwesomeIcon icon={faMapMarkerAlt} />
                      <Typography
                        sx={{ display: "inline", ml: 1 }}
                        variant="body1"
                      >
                        {state.user?.userLocation}
                      </Typography>
                    </Box>
                  )}
                  {state.user?.userUrl && (
                    <Box sx={{ mt: 2 }}>
                      <FontAwesomeIcon icon={faLink} />
                      <Typography
                        sx={{ display: "inline", ml: 1 }}
                        variant="body1"
                      >
                        {state.user?.userUrl}
                      </Typography>
                    </Box>
                  )}
                  {state.user?.userTweets && (
                    <Box sx={{ mt: 2 }}>
                      <FontAwesomeIcon icon={faTwitter} />
                      <Typography
                        sx={{ display: "inline", ml: 1 }}
                        variant="body1"
                      >
                        {state.user?.userTweets}
                      </Typography>
                    </Box>
                  )}
                  {state.user?.userLikes && (
                    <Box sx={{ mt: 2 }}>
                      <FontAwesomeIcon icon={faThumbsUp} />
                      <Typography
                        sx={{ display: "inline", ml: 1 }}
                        variant="body1"
                      >
                        {state.user?.userLikes}
                      </Typography>
                    </Box>
                  )}
                </>
              )}
            </Box>
          )}
        </Box>
      </Modal>
    </>
  );
}
