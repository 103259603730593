import {
  collection,
  addDoc,
  getDoc,
  getDocs,
  doc,
  arrayUnion,
  updateDoc,
  arrayRemove,
  writeBatch,
  query,
  orderBy,
  startAfter,
  endBefore,
  limit,
  documentId,
  where,
} from "firebase/firestore";
import { db } from "./config";

const fetchTweets = async (
  docsPerPage = null,
  latestDocId = null,
  next = true
) => {
  try {
    var tweets = [],
      tweetQuery;
    // limiting query to fetch limited number of docs on a page
    if (docsPerPage) {
      // for a specific page
      if (latestDocId) {
        // to fetch next tweets
        if (next) {
          tweetQuery = query(
            collection(db, "tweets"),
            orderBy(documentId()),
            startAfter(latestDocId),
            limit(docsPerPage)
          );
        }
        // to fetch previous tweets
        else {
          tweetQuery = query(
            collection(db, "tweets"),
            orderBy(documentId()),
            endBefore(latestDocId),
            limit(docsPerPage)
          );
        }
      }
      // for first page
      else {
        tweetQuery = query(
          collection(db, "tweets"),
          orderBy(documentId()),
          limit(docsPerPage)
        );
      }
    }
    // fetch all docs for use in other functions such as leaderboard calculations
    else {
      tweetQuery = query(collection(db, "tweets"), orderBy(documentId()));
    }
    const tweetsSnapshot = await getDocs(tweetQuery);
    tweetsSnapshot.forEach((snap) => {
      let tweet = {
        id: snap.id,
        ...snap.data(),
      };
      tweets.push(tweet);
    });
    return tweets;
  } catch (error) {
    console.log("fetch tweets error:", error);
    window.location = "/404";
    return [];
  }
};

const fetchTweetsWithAllComments = async (docsPerPage, latestDocId, next) => {
  try {
    var tweets = [];
    tweets = await fetchTweets(docsPerPage, latestDocId, next);
    for (let tweet of tweets) {
      // nested for loop here to get all comments for a tweet
      if (tweet?.comments?.length > 0) {
        try {
          for (let i = 0; i < tweet.comments.length; i++) {
            // get comment details object from the comments table
            const commentData = await getDoc(
              doc(db, "comments", tweet.comments[i])
            );
            if (commentData.exists()) {
              // append user's details in that comment object
              const userData = await getDoc(
                doc(db, "users", commentData.data().userId)
              );
              if (userData.exists()) {
                // add that comment+user object to the comments array inside the tweet
                tweet.comments[i] = {
                  commentId: commentData.id,
                  ...commentData.data(),
                  ...userData.data(),
                };
              }
            }
          }
        } catch (e) {
          console.log("fetch comments and users data error:", e);
        }
      }
    }
    return tweets;
  } catch (error) {
    console.log("fetch tweet error:", error);
    return [];
  }
};

const fetchTweetsWithoutHiddenComments = async (
  docsPerPage,
  latestDocId,
  next
) => {
  try {
    var tweets = [];
    tweets = await fetchTweets(docsPerPage, latestDocId, next);
    for (let tweet of tweets) {
      // nested for loop here to get all comments for a tweet
      let hiddenCommentIndices = [];
      if (tweet?.comments?.length > 0) {
        try {
          for (let i = 0; i < tweet.comments.length; i++) {
            // get comment details object from the comments table
            const commentData = await getDoc(
              doc(db, "comments", tweet.comments[i])
            );
            if (commentData.exists()) {
              // only append comments that aren't hidden
              if (!commentData.data()?.hidden) {
                // append user's details in that comment object
                const userData = await getDoc(
                  doc(db, "users", commentData.data().userId)
                );
                // add that comment+user object to the comments array inside the tweet
                if (userData.exists()) {
                  tweet.comments[i] = {
                    commentId: commentData.id,
                    ...commentData.data(),
                    ...userData.data(),
                  };
                }
              }
              // to remove ids of the comments that are hidden
              else {
                hiddenCommentIndices.push(i);
              }
            }
          }
        } catch (e) {
          console.log("fetch comments and users data error:", e);
        }
        let commentsArray = tweet.comments;
        tweet.comments = commentsArray.filter((item, index) => {
          return !hiddenCommentIndices.includes(index);
        });
      }
    }
    return tweets;
  } catch (error) {
    console.log("fetch tweet error:", error);
    return [];
  }
};

const fetchTwitterUser = async (userHandle) => {
  try {
    let user = {};
    const q = query(
      collection(db, "twitterUsers"),
      where("userHandle", "==", userHandle)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      user = { id: doc.id, ...doc.data() };
    });
    if (Object?.keys(user)?.length > 0) {
      return user;
    } else {
      throw Error("user doesn't exist");
    }
  } catch (error) {
    console.log("fetch twitter users error:", error);
    return {};
  }
};

const postComment = async (comment, tweetId, userId) => {
  try {
    const date = new Date();
    const newCommentRef = await addDoc(collection(db, "comments"), {
      body: comment,
      commentCreatedAt: date.toString(),
      userId: userId,
    });
    await updateDoc(doc(db, "tweets", tweetId), {
      comments: arrayUnion(newCommentRef.id),
    });
    return await Promise.resolve();
  } catch (e) {
    console.log("post comment error:", e);
    return await Promise.reject(e);
  }
};

const updateComment = async (commentText, commentId) => {
  try {
    const date = new Date();
    await updateDoc(doc(db, "comments", commentId), {
      body: commentText,
      commentEditedAt: date.toString(),
    });
    return await Promise.resolve();
  } catch (e) {
    console.log("update comment error:", e);
    return await Promise.reject(e);
  }
};

const deleteComment = async (commentId, tweetId) => {
  try {
    const batch = writeBatch(db);
    // first remove this comment from the tweets table
    // which has an array with all the comment IDs
    batch.update(doc(db, "tweets", tweetId), {
      comments: arrayRemove(commentId),
    });
    // delete this comments from the comments table after
    // it has been removed from the tweets comment array
    batch.delete(doc(db, "comments", commentId));
    await batch.commit();
    return await Promise.resolve();
  } catch (e) {
    console.log("delete comment error:", e);
    return await Promise.reject(e);
  }
};

const hideComments = async (commentIds) => {
  try {
    if (!Array.isArray(commentIds))
      throw Error("function parameter must be an array of string IDs");
    if (!commentIds.length > 0)
      return await Promise.resolve("no comments to hide");
    const batch = writeBatch(db);
    commentIds.forEach((commentId) => {
      if (commentId)
        batch.update(doc(db, "comments", commentId), { hidden: true });
    });
    await batch.commit();
    return await Promise.resolve();
  } catch (e) {
    console.log("hide comments error:", e);
    return await Promise.reject(e);
  }
};

const unhideComments = async (commentIds) => {
  try {
    if (!Array.isArray(commentIds))
      throw Error("function parameter must be an array of string IDs");
    if (!commentIds.length > 0)
      return await Promise.resolve("no comments to hide");
    const batch = writeBatch(db);
    commentIds.forEach((commentId) => {
      if (commentId)
        batch.update(doc(db, "comments", commentId), { hidden: false });
    });
    await batch.commit();
    return await Promise.resolve();
  } catch (e) {
    console.log("unhide comments error:", e);
    return await Promise.reject(e);
  }
};

const upvoteTweet = async (tweetId, userId) => {
  try {
    const tweetData = await getDoc(doc(db, "tweets", tweetId));
    if (!tweetData.exists()) throw Error("tweet doesn't exist");
    // if user has already upvoted then remove their upvote
    if (tweetData.data().upvotes?.includes(userId)) {
      updateDoc(doc(db, "tweets", tweetId), {
        upvotes: arrayRemove(userId),
      });
    }
    // otherwise add to upvote and remove from downvote
    else {
      const batch = writeBatch(db);
      batch.update(doc(db, "tweets", tweetId), {
        upvotes: arrayUnion(userId),
      });
      batch.update(doc(db, "tweets", tweetId), {
        downvotes: arrayRemove(userId),
      });
      await batch.commit();
    }
    return await Promise.resolve();
  } catch (e) {
    console.log("upvote tweet error:", e);
    return await Promise.reject(e);
  }
};

const downvoteTweet = async (tweetId, userId) => {
  try {
    const tweetData = await getDoc(doc(db, "tweets", tweetId));
    if (!tweetData.exists()) throw Error("tweet doesn't exist");
    // if user has already downvoted then remove their downvote
    if (tweetData.data().downvotes?.includes(userId)) {
      updateDoc(doc(db, "tweets", tweetId), {
        downvotes: arrayRemove(userId),
      });
    }
    // otherwise add to downvote and remove from upvote
    else {
      const batch = writeBatch(db);
      batch.update(doc(db, "tweets", tweetId), {
        downvotes: arrayUnion(userId),
      });
      batch.update(doc(db, "tweets", tweetId), {
        upvotes: arrayRemove(userId),
      });
      await batch.commit();
    }
    return await Promise.resolve();
  } catch (e) {
    console.log("downvote tweet error:", e);
    return await Promise.reject(e);
  }
};

const archiveTweets = async (tweetIds) => {
  try {
    if (!Array.isArray(tweetIds))
      throw Error("function parameter must be an array of string IDs");
    if (!tweetIds.length > 0)
      return await Promise.resolve("no tweets to archive");
    const batch = writeBatch(db);
    tweetIds.forEach((tweetId) => {
      batch.update(doc(db, "tweets", tweetId), { archived: true });
    });
    await batch.commit();
    return await Promise.resolve();
  } catch (e) {
    console.log("archive tweets error:", e);
    return await Promise.reject(e);
  }
};

const unarchiveTweets = async (tweetIds) => {
  try {
    if (!Array.isArray(tweetIds))
      throw Error("function parameter must be an array of string IDs");
    if (!tweetIds.length > 0)
      return await Promise.resolve("no tweets to unarchive");
    const batch = writeBatch(db);
    tweetIds.forEach((tweetId) => {
      batch.update(doc(db, "tweets", tweetId), { archived: false });
    });
    await batch.commit();
    return await Promise.resolve();
  } catch (e) {
    console.log("unarchive tweets error:", e);
    return await Promise.reject(e);
  }
};

export {
  fetchTweets,
  fetchTweetsWithAllComments,
  fetchTweetsWithoutHiddenComments,
  fetchTwitterUser,
  postComment,
  updateComment,
  deleteComment,
  upvoteTweet,
  downvoteTweet,
  archiveTweets,
  unarchiveTweets,
  hideComments,
  unhideComments,
};
