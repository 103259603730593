import React from "react";
import Navbar from "../components/Navbar";
import { Container, Typography, Box } from "@mui/material";

const NotFound = () => {
  return (
    <>
      <Navbar />
      <Container sx={{ display: "flex", justifyContent: "center" }}>
        <Box sx={{ mt: "10vh", px: "10%" }}>
          <Typography align="center" sx={{ fontSize: "9em" }} component="p">
            404
          </Typography>
          <Typography align="center" sx={{ fontSize: "1.2em" }} component="p">
            The page you are looking for might have been removed, or you do not
            have sufficient permissions to access it. Please{" "}
            <a href="">contact us</a> for more information.
          </Typography>
        </Box>
      </Container>
    </>
  );
};
export default NotFound;
