import Typography from "@mui/material/Typography";
import { Grid, Avatar } from "@mui/material";
import TwitterUserDetails from "./TwitterUserDetails";

export default function LeaderboardItem(props) {
  const gridStyle = {
    alignSelf: "center",
  };

  return (
    <>
      <div className="custom-hr" />
      <Grid container wrap="nowrap" spacing={1}>
        <Grid item xs={2} sx={gridStyle}>
          <Typography variant="body2" component="p" color="text.disabled">
            {props?.index + 1}
          </Typography>
        </Grid>
        <Grid item xs={2} sx={gridStyle}>
          {props?.leaderboardItem?.userIcon ? (
            <Avatar sx={{ width: 24, height: 24 }} aria-label="userIcon">
              <img src={props?.leaderboardItem?.userIcon} alt="user" />
            </Avatar>
          ) : (
            <Avatar
              sx={{ width: 24, height: 24 }}
              aria-label="userIcon"
            ></Avatar>
          )}
        </Grid>
        <Grid item xs={6} sx={gridStyle}>
          <Typography
            noWrap={true}
            variant="body2"
            component="p"
            color="text.primary"
          >
            <TwitterUserDetails
              userName={props?.leaderboardItem?.name}
              userHandle={props?.leaderboardItem?.handle}
            />
          </Typography>
        </Grid>
        <Grid item xs={2} sx={gridStyle}>
          <Typography
            fontWeight="600"
            variant="body2"
            component="p"
            color="text.primary"
          >
            {props?.leaderboardItem?.score}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
