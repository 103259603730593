const appConstants = {
  DATE_FORMAT: { year: "numeric", month: "long", day: "numeric" },
  REG_EXP_STRING_INSIDE_PARANTHESIS: /\(([^)]+)\)/,
  TWEETS_PER_PAGE: 10,
  TOXIC_THRESHOLD: 0.2,
  PREDICTIONS: {
    // blue
    IDENTITY_HATE: {
      key: "IDENTITY_HATE",
      label: "Identity Hate",
      labelBackgoundColor: "#b3e5fc",
      labelTextColor: "#00000080",
    },
    INSULT: {
      // yellow
      key: "INSULT",
      label: "Insult",
      labelBackgoundColor: "#ffecb3",
      labelTextColor: "#00000080",
    },
    OBSCENE: {
      // purple
      key: "OBSCENE",
      label: "Obscene",
      labelBackgoundColor: "#d1c4e9",
      labelTextColor: "#00000080",
    },
    THREAT: {
      // orange
      key: "THREAT",
      label: "Threat",
      labelBackgoundColor: "#ffe0b2",
      labelTextColor: "#00000080",
    },
    TOXIC: {
      // green
      key: "TOXIC",
      label: "Toxic",
      labelBackgoundColor: "#dcedc8",
      labelTextColor: "#00000080",
    },
    SEVERE_TOXIC: {
      // red
      key: "SEVERE_TOXIC",
      label: "Severely Toxic",
      labelBackgoundColor: "#f8bbd0",
      labelTextColor: "#00000080",
    },
  },
  TEST_DATA: [
    {
      id: "3imwf0VFfLzf3x0PbaMm",
      archived: true,
      userIcon: "",
      upvotes: ["6tL5Wqk6tMXHEyZ2ea9UqCZMG5C3"],
      userId: "twtuser1",
      downvotes: [],
      body: "Nulla vitae odio maximus, pretium lorem vehicula, consectetur eros.",
      tweetCreatedAt: "2020-01-19T07:35:16.649Z",
      userHandle: "Twitter User",
    },
    {
      id: "4dkewJqd9p7Z4XuiyZcC",
      body: "this is for testing",
      comments: [
        {
          commentId: "ShYEJ0fVnEF0iirl8vCp",
          commentCreatedAt:
            "Tue Apr 12 2022 15:56:05 GMT+0500 (Pakistan Standard Time)",
          userId: "6tL5Wqk6tMXHEyZ2ea9UqCZMG5C3",
          body: "the very first comment for this testing tweet!",
          isAdmin: true,
          userHandle: "xcv",
          createdAt: "1647586874581",
          email: "xcv@gmail.com",
        },
        {
          commentId: "9eaKLfVTDrOXNcodky1K",
          commentCreatedAt:
            "Fri Apr 22 2022 15:41:47 GMT+0500 (Pakistan Standard Time)",
          body: "testing...",
          userId: "6tL5Wqk6tMXHEyZ2ea9UqCZMG5C3",
          createdAt: "1647586874581",
          email: "xcv@gmail.com",
          isAdmin: true,
          userHandle: "xcv",
        },
      ],
      upvotes: ["6tL5Wqk6tMXHEyZ2ea9UqCZMG5C3"],
      userHandle: "John Doe",
      tweetCreatedAt: "2020-05-19T07:35:16.649Z",
      downvotes: [],
      userId: "twtuser2",
    },
    {
      id: "VC2CEpL7IUjsQz5vcqzt",
      body: "lorem ipsum dolor sit ammet",
      downvotes: ["KT0jMY9iQxksu96CRTog"],
      userIcon: "",
      userId: "twtuser1",
      archived: false,
      tweetCreatedAt: "2020-06-19T07:35:16.649Z",
      upvotes: [
        "sK6Li4tX7vl8bqQ0QN7S ",
        "KT0jMY9iQxksu96CRTog",
        "uAFrkqUULVzhxOEUMKuT",
      ],
      comments: [
        {
          commentId: "IRXOVANM1cNv1nAjlpS3",
          userId: "sK6Li4tX7vl8bqQ0QN7S",
          commentCreatedAt: "2020-07-28T07:42:43.837Z",
          body: "newest comment",
          userHandle: "user test",
          userIcon:
            "https://firebasestorage.googleapis.com/v0/b/cobot-333cc.appspot.com/o/no-img.png?alt=media",
          userCreatedAt: "2020-07-06T13:53:34.168Z",
          email: "usetest@gmail.com",
        },
        {
          commentId: "jqBAtpYLyMVbQ0RoFFfj",
          createdAt: "1647427504880",
          body: "just the three then?",
          userId: "tyoGbhNUzgeBsnH40jVhrpnV9aq1",
          userImage:
            "https://firebasestorage.googleapis.com/v0/b/cobot-333cc.appspot.com/o/30211730.png?alt=media",
          userHandle: "zxc",
          email: "zxc@gmail.com",
        },
        {
          commentId: "dTZVOpzXQL0CyoGWosI9",
          body: "srfgerfhvearh",
          userId: "6tL5Wqk6tMXHEyZ2ea9UqCZMG5C3",
          commentCreatedAt:
            "Tue Mar 22 2022 15:05:39 GMT+0500 (Pakistan Standard Time)",
          userHandle: "xcv",
          email: "xcv@gmail.com",
          isAdmin: true,
          createdAt: "1647586874581",
        },
        {
          commentId: "yYQB82BcmWpGTYZwzXHU",
          body: "bla bla bla",
          commentCreatedAt:
            "Tue Mar 22 2022 15:23:54 GMT+0500 (Pakistan Standard Time)",
          userId: "6tL5Wqk6tMXHEyZ2ea9UqCZMG5C3",
          email: "xcv@gmail.com",
          createdAt: "1647586874581",
          isAdmin: true,
          userHandle: "xcv",
        },
        {
          commentId: "aT7mw1Yk7EKcLUlj6LKJ",
          body: "testing...",
          commentCreatedAt:
            "Tue Mar 22 2022 15:33:59 GMT+0500 (Pakistan Standard Time)",
          userId: "6tL5Wqk6tMXHEyZ2ea9UqCZMG5C3",
          userHandle: "xcv",
          isAdmin: true,
          email: "xcv@gmail.com",
          createdAt: "1647586874581",
        },
        {
          commentId: "5AnHeEANWRk7Jvl3Tghe",
          body: "more testing",
          userId: "6tL5Wqk6tMXHEyZ2ea9UqCZMG5C3",
          commentCreatedAt:
            "Tue Mar 22 2022 15:38:19 GMT+0500 (Pakistan Standard Time)",
          userHandle: "xcv",
          email: "xcv@gmail.com",
          isAdmin: true,
          createdAt: "1647586874581",
        },
        {
          commentId: "acL3VMZVXWpkfcPMPmBY",
          body: "lorem ipsum",
          commentCreatedAt:
            "Tue Mar 22 2022 15:40:00 GMT+0500 (Pakistan Standard Time)",
          userId: "6tL5Wqk6tMXHEyZ2ea9UqCZMG5C3",
          createdAt: "1647586874581",
          isAdmin: true,
          email: "xcv@gmail.com",
          userHandle: "xcv",
        },
        {
          commentId: "yVgX1QpGgekx1zMxpFlX",
          commentCreatedAt:
            "Tue Mar 22 2022 15:40:37 GMT+0500 (Pakistan Standard Time)",
          userId: "6tL5Wqk6tMXHEyZ2ea9UqCZMG5C3",
          body: "not cool",
          userHandle: "xcv",
          createdAt: "1647586874581",
          email: "xcv@gmail.com",
          isAdmin: true,
        },
        {
          commentId: "NGyWbBi9Qo4ZhnY6BTHa",
          body: "ok",
          userId: "6tL5Wqk6tMXHEyZ2ea9UqCZMG5C3",
          commentCreatedAt:
            "Tue Mar 22 2022 15:41:25 GMT+0500 (Pakistan Standard Time)",
          createdAt: "1647586874581",
          email: "xcv@gmail.com",
          userHandle: "xcv",
          isAdmin: true,
        },
        {
          commentId: "ODet2RDIf1sz77wQwkyd",
          body: "working but not properly",
          commentCreatedAt:
            "Tue Mar 22 2022 15:42:09 GMT+0500 (Pakistan Standard Time)",
          userId: "6tL5Wqk6tMXHEyZ2ea9UqCZMG5C3",
          isAdmin: true,
          email: "xcv@gmail.com",
          userHandle: "xcv",
          createdAt: "1647586874581",
        },
      ],
      userHandle: "Twitter User",
    },
    {
      id: "XYJ9lSmykQgy15JNGGJc",
      userHandle: "Another Twitter Guy",
      userId: "twtuser3",
      body: "Quisque nec iaculis leo. Curabitur malesuada feugiat facilisis. Nulla vitae odio maximus, pretium lorem vehicula, consectetur eros.",
      upvotes: [
        "KT0jMY9iQxksu96CRTog",
        "SHHiJUDQMB7fwqahcnuK",
        "c88Jysv4DHN8r9SHfyAU5tjesS12",
        "HfT5dOsxUjQKspjglO3pMbN2mzj1",
        "6tL5Wqk6tMXHEyZ2ea9UqCZMG5C3",
      ],
      archived: false,
      userIcon: "test",
      comments: [
        {
          commentId: "MCvhxBmM1OBKIcs80eT4",
          commentCreatedAt:
            "Tue Mar 22 2022 13:00:04 GMT+0500 (Pakistan Standard Time)",
          userId: "6tL5Wqk6tMXHEyZ2ea9UqCZMG5C3",
          hidden: false,
          body: "newest test",
          isAdmin: true,
          createdAt: "1647586874581",
          userHandle: "xcv",
          email: "xcv@gmail.com",
        },
        {
          commentId: "DDt2sfXzzENLVBd6Ot3m",
          body: "bla",
          userId: "6tL5Wqk6tMXHEyZ2ea9UqCZMG5C3",
          commentCreatedAt:
            "Tue Mar 22 2022 15:18:40 GMT+0500 (Pakistan Standard Time)",
          createdAt: "1647586874581",
          isAdmin: true,
          userHandle: "xcv",
          email: "xcv@gmail.com",
        },
        {
          commentId: "TUofp9gLu5FsESmM9vOc",
          body: "testing 123...",
          commentCreatedAt:
            "Tue Mar 22 2022 15:21:54 GMT+0500 (Pakistan Standard Time)",
          userId: "6tL5Wqk6tMXHEyZ2ea9UqCZMG5C3",
          email: "xcv@gmail.com",
          createdAt: "1647586874581",
          isAdmin: true,
          userHandle: "xcv",
        },
        {
          commentId: "TNIrWQ8gd6U8qdPECDD3",
          userId: "6tL5Wqk6tMXHEyZ2ea9UqCZMG5C3",
          body: "ugh",
          commentCreatedAt:
            "Tue Mar 22 2022 15:39:01 GMT+0500 (Pakistan Standard Time)",
          isAdmin: true,
          createdAt: "1647586874581",
          email: "xcv@gmail.com",
          userHandle: "xcv",
        },
        {
          commentId: "luMsS5dA7lIM0WFRN1F3",
          body: "omg im posting waaaaaaaayy too many comments",
          userId: "6tL5Wqk6tMXHEyZ2ea9UqCZMG5C3",
          commentCreatedAt:
            "Tue Mar 22 2022 15:43:30 GMT+0500 (Pakistan Standard Time)",
          userHandle: "xcv",
          email: "xcv@gmail.com",
          createdAt: "1647586874581",
          isAdmin: true,
        },
        {
          commentId: "IGOL4U3Gfv4byu8gfuRB",
          body: "hi",
          commentCreatedAt:
            "Mon Apr 18 2022 16:27:19 GMT+0500 (Pakistan Standard Time)",
          userId: "6tL5Wqk6tMXHEyZ2ea9UqCZMG5C3",
          createdAt: "1647586874581",
          email: "xcv@gmail.com",
          isAdmin: true,
          userHandle: "xcv",
        },
      ],
      downvotes: [],
      tweetCreatedAt: "2020-05-19T07:35:16.649Z",
    },
    {
      id: "oQpCcLKyYR0HDI6a2eqO",
      tweetCreatedAt: "2020-04-19T07:35:16.649Z",
      archived: false,
      upvotes: ["6tL5Wqk6tMXHEyZ2ea9UqCZMG5C3"],
      comments: [
        {
          commentId: "fxV7O6Sp6j7lc1HBJxoe",
          userId: "c88Jysv4DHN8r9SHfyAU5tjesS12",
          body: "the very first comment for this tweet!!!",
          commentCreatedAt:
            "Thu Mar 24 2022 11:41:26 GMT+0500 (Pakistan Standard Time)",
          createdAt: "1647344582677",
          userHandle: "asd",
          email: "asd@gmail.com",
        },
      ],
      downvotes: [],
      userId: "twtuser2",
      body: "Etiam tempus, metus in elementum varius, ut viverra purus ex non justo.",
      userHandle: "John Doe",
    },
    {
      id: "uS4HGeS0lLjieUEpDLCA",
      upvotes: ["6tL5Wqk6tMXHEyZ2ea9UqCZMG5C3"],
      downvotes: [],
      body: "hello",
      userHandle: "John Doe",
      tweetCreatedAt: "2020-05-19T07:35:16.649Z",
      userId: "twtuser2",
    },
  ],
};

export default appConstants;
