import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import { updateComment, deleteComment } from "../firebaseFunctions/tweets";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "450px",
  minWidth: "250px",
  width: "65%",
  bgcolor: "background.paper",
  border: 0,
  borderRadius: 5,
  p: 4,
};

export default function BasicModal(props) {
  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState({
    comment: "",
    errorMsg: "",
    loading: false,
    deleting: false,
  });
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  React.useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      comment: props.commentText,
    }));
  }, []);

  const handleCommentChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      comment: e.target.value,
    }));
  };

  const onSaveComment = async () => {
    try {
      if (
        JSON.parse(localStorage.getItem("user")).userId !== props.userId ||
        !state.comment?.trim()
      )
        return;
      setState((prevState) => ({
        ...prevState,
        loading: true,
      }));
      await updateComment(state.comment, props.commentId);
      props.setReload(!props.reload);
      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
      handleClose();
    } catch (e) {
      console.log("save comment error:", e);
      setState((prevState) => ({
        ...prevState,
        loading: false,
        errorMsg:
          "There was a problem while saving that comment, please refresh and try again.",
      }));
    }
  };

  const onDeleteComment = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        loading: true,
      }));
      await deleteComment(props.commentId, props.tweetId);
      props.setReload(!props.reload);
      setState((prevState) => ({
        ...prevState,
        loading: false,
        deleting: false,
      }));
      handleClose();
    } catch (e) {
      console.log("delete comment error:", e);
      setState((prevState) => ({
        ...prevState,
        loading: false,
        errorMsg:
          "There was a problem while deleting that comment, please refresh and try again.",
      }));
    }
  };

  return (
    <>
      <FontAwesomeIcon
        className="icon-size-medium"
        icon={faEdit}
        onClick={handleOpen}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography
            id="modal-modal-title"
            variant="body1"
            align="center"
            fontWeight="bold"
          >
            Edit Comment
          </Typography>
          <Box mt={1}>
            <input
              type="text"
              className="custom-comment-input"
              name="comment"
              defaultValue={state.comment}
              onChange={handleCommentChange}
            ></input>
            <Typography align="center" my={1} variant="body2" color="error">
              {state.errorMsg}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                marginTop: 3,
                padding: 0,
              }}
            >
              {state.loading ? (
                <>
                  <CircularProgress
                    size={15}
                    sx={{ marginY: 1, marginX: "auto" }}
                  />
                </>
              ) : state.deleting ? (
                <>
                  <Typography color="error" variant="body2" mb={1}>
                    This comment will be deleted.
                  </Typography>
                  <div>
                    <Button
                      color="error"
                      size="small"
                      variant="contained"
                      sx={{ boxShadow: 0 }}
                      onClick={onDeleteComment}
                    >
                      Ok
                    </Button>
                    <Button
                      size="small"
                      variant="outlined"
                      sx={{ boxShadow: 0, ml: 1 }}
                      onClick={() => {
                        setState((prevState) => ({
                          ...prevState,
                          deleting: false,
                        }));
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <Button
                      color="error"
                      size="small"
                      variant="contained"
                      sx={{ boxShadow: 0 }}
                      onClick={() => {
                        setState((prevState) => ({
                          ...prevState,
                          deleting: true,
                        }));
                      }}
                    >
                      Delete
                    </Button>
                  </div>
                  <div>
                    <Button
                      size="small"
                      variant="contained"
                      sx={{ boxShadow: 0, mr: 1 }}
                      onClick={onSaveComment}
                    >
                      Save
                    </Button>
                    <Button
                      size="small"
                      variant="outlined"
                      sx={{ boxShadow: 0 }}
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                  </div>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
