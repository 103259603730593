import * as React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { getAuth, signOut } from "firebase/auth";

const logoutButtonStyle = {
  color: "text.secondary",
  textTransform: "none",
};

// todo: show loader overlay during logout
export default function LogoutButton() {
  const auth = getAuth();
  const logout = () => {
    signOut(auth)
      .then(() => {
        window.location.reload();
      })
      .catch((e) => {
        console.log("logout error:", e);
      });
  };

  return (
    <div style={{ display: "flex" }}>
      <Button sx={logoutButtonStyle} onClick={logout}>
        <Typography>logout</Typography>
      </Button>
    </div>
  );
}
