import "./App.css";
import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getUserData } from "./firebaseFunctions/users";
import Tweets from "./views/Tweets";
import Admin from "./views/Admin";
import NotFound from "./views/NotFound";

const App = () => {
  const auth = getAuth();
  onAuthStateChanged(auth, async (user) => {
    if (user && user?.hasOwnProperty("uid")) {
      const userData = await getUserData(user.uid);
      localStorage.setItem("user", JSON.stringify(userData));
      // console.log("logged in!");
    } else {
      localStorage.clear();
      // console.log("logged out!");
    }
  });

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Tweets />} />
          <Route path="*" element={<Navigate to="/" />} />
          {JSON.parse(localStorage.getItem("user")) &&
            "isAdmin" in JSON.parse(localStorage.getItem("user")) &&
            JSON.parse(localStorage.getItem("user")).isAdmin && (
              <Route exact path="/admin" element={<Admin />} />
            )}
          <Route exact path="/404" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
