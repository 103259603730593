import appConstants from "./appConstants";

const extractAuthErrorMessage = (firebaseAuthError) => {
  try {
    const matches =
      appConstants.REG_EXP_STRING_INSIDE_PARANTHESIS.exec(firebaseAuthError);
    let errorMessage =
      matches?.length > 0
        ? matches[1]?.replace("auth/", "")?.replaceAll("-", " ")
        : "authentication error, please refresh and try again";
    let formattedErrorMessage =
      errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1) + ".";
    return formattedErrorMessage;
  } catch (e) {
    console.log("extract auth message error:", e);
  }
};

const timeAgo = (date) => {
  try {
    const MINUTE = 60,
      HOUR = MINUTE * 60,
      DAY = HOUR * 24,
      WEEK = DAY * 7,
      MONTH = DAY * 30,
      YEAR = DAY * 365;
    const secondsAgo = Math.round((+new Date() - date) / 1000);
    let divisor = null;
    let unit = null;

    if (secondsAgo < MINUTE) {
      return secondsAgo + "s";
    } else if (secondsAgo < HOUR) {
      [divisor, unit] = [MINUTE, "m"];
    } else if (secondsAgo < DAY) {
      [divisor, unit] = [HOUR, "h"];
    } else if (secondsAgo < WEEK) {
      [divisor, unit] = [DAY, "d"];
    } else if (secondsAgo < MONTH) {
      [divisor, unit] = [WEEK, "w"];
    } else if (secondsAgo < YEAR) {
      [divisor, unit] = [MONTH, "m"];
    } else if (secondsAgo > YEAR) {
      [divisor, unit] = [YEAR, "y"];
    }

    const count = Math.floor(secondsAgo / divisor);
    return `${count}${unit}`;
  } catch (e) {
    console.log("calculate time ago error:", e);
    return "";
  }
};

const getTimeAgo = (timestamp) => {
  try {
    const newDate = new Date(timestamp);
    if (Object.prototype.toString.call(newDate) === "[object Date]") {
      if (isNaN(newDate)) {
        throw Error("invalid date");
      } else {
        let date = timeAgo(new Date(newDate));
        return date;
      }
    } else {
      throw Error("not a date");
    }
  } catch (e) {
    console.log("return time ago error:", e);
    return "";
  }
};

export { extractAuthErrorMessage, getTimeAgo };
