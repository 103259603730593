import * as React from "react";
import {
  Card,
  CardContent,
  Collapse,
  IconButton,
  Typography,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import { styled } from "@mui/material/styles";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginTop: !expand ? "5px" : "-5px",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.short,
  }),
}));

export default function AboutCobot(props) {
  const [expanded, setExpanded] = React.useState(false);

  React.useEffect(() => {
    setExpanded(props.expanded);
  }, []);

  return (
    <Card
      sx={{
        boxShadow: 0,
        borderRadius: 4,
        marginBottom: 2,
        maxWidth: "-webkit-fill-available",
        marginX: "auto",
      }}
    >
      <CardContent>
        <Typography mt={1} variant="h5" component="h2" color="text.primary">
          What is CoBot?
          <ExpandMore
            expand={expanded}
            onClick={() => {
              setExpanded(!expanded);
            }}
            aria-expanded={expanded}
            aria-label="show more"
            sx={{
              p: 0,
              color: "inherit",
              float: "right",
              marginTop: 1,
            }}
          >
            <FontAwesomeIcon className="icon-size-large" icon={faChevronDown} />
          </ExpandMore>
        </Typography>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <div className="custom-hr" />
          <Typography component="p" variant="body2" color="text.primary">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
            accumsan ante quis lacus congue, eu dictum mi porta.
          </Typography>
          <Typography component="p" variant="body2" color="text.primary">
            Etiam tempus, metus in elementum varius, risus mi elementum mauris,
            ut viverra purus ex non justo.
          </Typography>
          <Typography component="p" variant="body2" color="text.primary">
            Quisque nec iaculis leo. Curabitur malesuada feugiat facilisis.
            Nulla vitae odio maximus, pretium lorem vehicula, consectetur eros.
          </Typography>
        </Collapse>
      </CardContent>
    </Card>
  );
}
